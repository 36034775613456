<ion-content>
  <div class="bg-light overflow-x-clip">
    <sophus-navbar></sophus-navbar>
    <div class="app-container py-2 md:pt-16 md:pb-0 lg:pt-32 xl:pt-40 xl:pb-24">
      <div class="grid md:grid-cols-2 items-center gap-16">
        <div class="">
          <h1 class="text-4xl">Client Experience</h1>
          <p class="text-xl font-light">
            With Sophus, clients get expert information coupled with the support
            to make real changes
          </p>
          <button
            class="rounded-md bg-primary text-white py-3 px-5 mt-4"
            appScrollTo="cta"
          >
            Book Demo
          </button>
        </div>
        <div class="flex self-stretch">
          <div class="w-full h-full app-client-image"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-curved-right-light-background py-16"></div>

  <div class="py-28">
    <div class="">
      <div class="app-container">
        <h2 class="text-3xl text-center mb-12">
          See how we help people create behaviour change, for good
        </h2>
      </div>

      <ion-slides #ionSlide [options]="slideOpts">
        <ion-slide>
          <div class="app-container">
            <ion-grid>
              <ion-row>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex items-center justify-center"
                >
                  <div class="py-8 md:p-12 md:pl-0 flex justify-center">
                    <div class="slide flex justify-center">
                      <div class="slide-blobs rounded-2xl ion-border">
                        <img
                          class="object-contain rounded-xl"
                          src="../../../../assets/images/self-guided-n.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex flex-col justify-center"
                >
                  <div
                    style="min-height: 24rem"
                    class="flex items-start md:items-center"
                  >
                    <div class="text-center md:text-left py-8 md:p-12 md:pr-0">
                      <h3 class="text-2xl mb-6">
                        A Suite of Self-Guided Digital Programs
                      </h3>
                      <p>
                        Our digital programs are created by our team of
                        dietitians and consulting experts that highlight the
                        most important information needed for a range of health
                        goals.
                      </p>
                      <p>
                        <a
                          href="../../../../assets/files/Sophus Nutrition Programs Library (June 2021).pdf"
                          download
                        >
                          Explore the Sophus program library
                          <ion-icon
                            class="-mb-1"
                            name="chevron-forward-outline"
                          ></ion-icon>
                        </a>
                      </p>
                      <ng-container
                        *ngTemplateOutlet="sliderControls"
                      ></ng-container>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-slide>
        <ion-slide>
          <div class="app-container">
            <ion-grid>
              <ion-row>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex items-center justify-center"
                >
                  <div class="py-8 md:p-12 md:pl-0 flex justify-center">
                    <div class="slide flex justify-center">
                      <div class="slide-blobs rounded-2xl ion-border">
                        <img
                          class="object-contain rounded-xl"
                          src="../../../../assets/images/coaching-n.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex flex-col justify-center"
                >
                  <div
                    style="min-height: 24rem"
                    class="flex items-start md:items-center"
                  >
                    <div class="text-center md:text-left py-8 md:p-12 md:pr-0">
                      <h3 class="text-2xl mb-6">Dietitian Coaching</h3>
                      <p>
                        Connect with your own personal dietitian for even
                        further tailored support. Our qualified dietitians are
                        there to answer your questions and keep you motivated
                        all from the secure message portal in the Sophus app.
                      </p>
                      <ng-container
                        *ngTemplateOutlet="sliderControls"
                      ></ng-container>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-slide>
        <ion-slide>
          <div class="app-container">
            <ion-grid>
              <ion-row>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex items-center justify-center"
                >
                  <div class="py-8 md:p-12 md:pl-0 flex justify-center">
                    <div class="slide flex justify-center">
                      <div class="slide-blobs rounded-2xl ion-border">
                        <img
                          class="object-contain rounded-xl"
                          src="../../../../assets/images/evidence-based-n.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex flex-col justify-center"
                >
                  <div
                    style="min-height: 24rem"
                    class="flex items-start md:items-center"
                  >
                    <div class="text-center md:text-left py-8 md:p-12 md:pr-0">
                      <h3 class="text-2xl mb-6">Evidence-based Nutrition</h3>
                      <p>
                        Our programs are created and updated regularly by our
                        dietitians to reflect the most up to date research in
                        nutrition and behaviour change.
                      </p>
                      <ng-container
                        *ngTemplateOutlet="sliderControls"
                      ></ng-container>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-slide>
        <ion-slide>
          <div class="app-container">
            <ion-grid>
              <ion-row>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex items-center justify-center"
                >
                  <div class="py-8 md:p-12 md:pl-0 flex justify-center">
                    <div class="slide flex justify-center">
                      <div class="slide-blobs rounded-2xl ion-border">
                        <img
                          class="object-contain rounded-xl"
                          src="../../../../assets/images/behaviour-n.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col
                  size="12"
                  size-md="6"
                  class="flex flex-col justify-center"
                >
                  <div
                    style="min-height: 24rem"
                    class="flex items-start md:items-center"
                  >
                    <div class="text-center md:text-left py-8 md:p-12 md:pr-0">
                      <h3 class="text-2xl mb-6">
                        A focus on behaviour, not numbers
                      </h3>
                      <p>
                        Unlike most nutrition apps available, Sophus takes the
                        focus off counting calories or tracking weight and puts
                        the spotlight on what really matters; creating
                        sustainable behaviour change around food choices to
                        improve your health.
                      </p>
                      <ng-container
                        *ngTemplateOutlet="sliderControls"
                      ></ng-container>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-slide>
      </ion-slides>
      <div class="app-container">
        <h2 class="text-3xl mt-12 md:mt-28 mb-16 max-w-lg mx-auto text-center">
          Sophus puts the knowledge and tools for better health in your hands.
        </h2>
        <div class="grid md:grid-cols-3 gap-4 md:gap-8 text-center">
          <div class="flex flex-col items-center">
            <div class="flex text-xl mb-2">
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
            </div>
            <p>
              "Great app! Love the journal feature! It makes tracking my what I
              eat so easy."
            </p>
          </div>
          <div class="flex flex-col items-center">
            <div class="flex text-xl mb-2">
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
            </div>
            <p>
              "It’s amazing how much better I feel at work during my training,
              how much better I perform and how well I recovered."
            </p>
          </div>
          <div class="flex flex-col items-center">
            <div class="flex text-xl mb-2">
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
              <ion-icon class="text-yellow-300" name="star"></ion-icon>
            </div>
            <p>
              "Love the content and recipes. Easy to view content drip-fed
              including healthy and performance based recipes."
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-28">
    <div class="app-container">
      <div class="max-w-sm mx-auto text-center">
        <h2 class="text-3xl">Understand how Sophus can work for you</h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <ng-template #sliderControls>
    <div class="flex justify-center md:justify-start">
      <div class="flex items-center text-3xl mt-4 -mx-2">
        <ion-icon
          class="m-2"
          [class.text-light]="slideIndex === 0"
          [class.cursor-pointer]="slideIndex !== 0"
          name="arrow-back-sharp"
          (click)="prevSlide()"
        ></ion-icon>
        <div class="dot m-1" [class.active]="slideIndex === 0"></div>
        <div class="dot m-1" [class.active]="slideIndex === 1"></div>
        <div class="dot m-1" [class.active]="slideIndex === 2"></div>
        <div class="dot m-1" [class.active]="slideIndex === 3"></div>
        <ion-icon
          class="m-2"
          [class.text-light]="slideIndex === 3"
          [class.cursor-pointer]="slideIndex !== 3"
          name="arrow-forward-sharp"
          (click)="nextSlide()"
        ></ion-icon>
      </div>
    </div>
  </ng-template>

  <sophus-footer></sophus-footer>
</ion-content>
