import { Component, OnInit } from "@angular/core";

@Component({
  selector: "sophus-sports",
  templateUrl: "./sports.component.html",
  styleUrls: ["./sports.component.scss"],
})
export class SportsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
