<ion-content>
  <div class="bg-light overflow-x-clip">
    <sophus-navbar></sophus-navbar>
    <div class="app-container py-2 md:pt-16 md:pb-0 lg:pt-32 xl:pt-40 xl:pb-24">
      <div class="grid md:grid-cols-2 items-center gap-16">
        <div class="">
          <h1 class="text-4xl">
            Accessible and practical nutrition support for your patients
          </h1>
          <p class="text-xl font-light">
            Sophus provides your patients with expert nutrition advice through
            our suite of digital nutrition programs that help your patients
            manage their health, from lifestyle goals to chronic conditions
          </p>
          <button
            class="rounded-md bg-primary text-white py-3 px-5 mt-4"
            appScrollTo="cta"
          >
            Learn More
          </button>
        </div>
        <div class="flex self-stretch">
          <div class="w-full h-full app-medical-image"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-curved-right-light-background py-16"></div>

  <div class="py-24 lg:py-32 xl:pt-40 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-2 items-center gap-8 md:gap-16 xl:gap-24">
        <div class="flex justify-center items-center relative z-10">
          <div class="app-blob-background">
            <img
              style="max-height: 26rem"
              class="rounded-xl"
              src="../../../../assets/images/health-provider.jpg"
            />
          </div>
        </div>
        <div class="relative z-20 order-first md:order-last">
          <h2 class="text-3xl">
            Help your patients look after themselves when you're not there
          </h2>
          <p>
            The Sophus app gives your patients access to the tools they need to
            eat well for managing their conditions.
          </p>
          <p>
            Our suite of diverse digital nutrition programs are delivered with a
            focus on behaviour change, using technology to help support your
            patients with effective guidance and advice no matter where they
            are.
          </p>
          <p>
            <a routerLink="/experience">
              Learn more about your patients Sophus experience
              <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
            </a>
          </p>
          <p>
            <a
              href="../../../../assets/files/Sophus Nutrition Programs Library (June 2021).pdf"
              download
            >
              Explore the Sophus program library
              <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="app-curved-top-light-background pt-20"></div>
  <div class="bg-light pt-12 xl:pt-20 pb-24">
    <div class="app-container text-center">
      <h2 class="text-3xl max-w-4xl mx-auto">
        Sophus is innovating in a time of healthcare change
      </h2>
      <p class="max-w-3xl mx-auto">
        People's expectations around accessing healthcare are changing, yet
        traditional services are still catching up. Here are some of the latest
        perspectives on this:
      </p>
      <div class="grid md:grid-cols-3 gap-8 md:gap-24 py-12">
        <div class="flex flex-col items-center">
          <div
            class="bg-tertiaryFaded h-20 w-20 mb-6 rounded-full flex justify-center items-center"
          >
            <img class="ml-2" src="../../../../assets/images/handycam.svg" />
          </div>
          <p>
            Particularly in the wake of COVID-19
            <b>
              more than half of healthcare consumers would chose virtual care
            </b>
            if given the choice.
          </p>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="bg-tertiaryFaded h-20 w-20 mb-6 rounded-full flex justify-center items-center"
          >
            <img class="ml-1" src="../../../../assets/images/apple-2.svg" />
          </div>
          <p>
            One in five said that
            <b>reliable digital tools to help them understand their habits</b>
            would motivate them in managing their health.
          </p>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="bg-tertiaryFaded h-20 w-20 mb-6 rounded-full flex justify-center items-center"
          >
            <img src="../../../../assets/images/watch.svg" />
          </div>
          <p>
            <b>26% of consumers</b> are willing to switch to a healthcare
            provider that
            <b>offers a high-quality digital service.</b>
          </p>
        </div>
      </div>
      <p class="text-lightgrey text-xs text-center xl:text-right">
        Accenture 2020 Digital Health Consumer Survey
      </p>
    </div>
  </div>

  <div class="bg-primary pt-24">
    <div class="app-container text-white text-center">
      <h2 class="text-3xl mb-6">
        Sophus’ digital solution to nutritional care is where the future is
      </h2>
      <p class="text-xl text-light mx-auto mb-0 font-light max-w-3xl">
        Easily integrated into any professional medical practice, it’s an ideal
        way to improve patient outcomes
      </p>
    </div>
  </div>
  <div class="app-curved-bottom-primary-background pb-24"></div>

  <div class="py-24 xl:py-40 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-2 gap-16">
        <div class="flex justify-center items-center relative z-10">
          <div class="app-blob-background-landscape">
            <img
              class="rounded-xl"
              src="../../../../assets/images/u-decide.jpg"
            />
          </div>
        </div>
        <div class="relative z-20">
          <h2 class="text-3xl">Working with Sophus</h2>
          <p>
            The Sophus team is working closely with the dietitians and
            researchers at the Princess Alexandra hospital to develop a digital
            nutrition education program specifically to support patients with
            complex chronic medical conditions.
          </p>
          <p>
            <em>
              "Using Sophus’ technology, we’ll be able to analyse data around
              engagement and consumer preference to help shape the way we
              support to our chronic disease patients into the future."
            </em>
          </p>
          <div class="flex">
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
          </div>
          <div class="flex mt-6">
            <div class="">
              <p class="mb-0"><b>Dr Ingrid Hickman</b></p>
              <p class="mb-0">Lead researcher of the U-Decide study</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-24 xl:pb-40">
    <div class="app-container">
      <div class="text-center max-w-md mx-auto">
        <h2 class="text-3xl">
          Understand how Sophus can work for your patients
        </h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
      </div>
      <div class="max-w-sm mx-auto">
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
