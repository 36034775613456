<ion-content>
  <div class="bg-light overflow-x-clip">
    <sophus-navbar></sophus-navbar>
    <div class="app-container py-2 md:pt-16 md:pb-0 lg:pt-32 xl:pt-40 xl:pb-24">
      <div class="grid md:grid-cols-2 items-center gap-24">
        <div class="">
          <h1 class="text-4xl">
            A corporate nutrition program for your entire team
          </h1>
          <p class="text-xl font-light">
            Sophus is designed to provide a tailored approach to corporate
            nutrition – so your team, wherever they are, can perform at their
            best
          </p>
          <button
            class="rounded-md bg-primary text-white py-3 px-5 mt-4"
            appScrollTo="cta"
          >
            Book Demo
          </button>
        </div>
        <div class="flex self-stretch">
          <div class="w-full h-full app-employers-image"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-curved-right-light-background py-16"></div>

  <div class="py-24 lg:py-32 xl:pt-40 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-2 items-center gap-8 md:gap-16 xl:gap-24">
        <div class="flex justify-center items-center relative z-10">
          <div class="app-blob-background">
            <img
              style="max-height: 26rem"
              class="rounded-xl"
              src="../../../../assets/images/corporate.jpg"
            />
          </div>
        </div>
        <div class="relative z-20 order-first md:order-last">
          <h2 class="text-3xl">
            Supporting the individuals that support your enterprise
          </h2>
          <p>
            Healthy staff are productive staff. By utilising a digital nutrition
            program combined with a focus on behavioural change, Sophus caters
            to the varied health needs of your team so they’re responsive,
            healthier, and happier at work.
          </p>
          <p>
            Our diverse range of customisable programs help your employees to
            improve their day-to-day health, which means better attendance, and
            boosted productivity in the workplace.
          </p>
          <p>
            <a routerLink="/experience">
              Learn more about your employee's Sophus experience
              <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
            </a>
          </p>
          <p>
            <a
              href="../../../../assets/files/Sophus Nutrition Programs Library (June 2021).pdf"
              download
            >
              Explore the Sophus program library
              <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="app-curved-top-light-background pt-20"></div>
  <div class="bg-light pt-12 xl:pt-20 pb-24">
    <div class="app-container">
      <div>
        <h2 class="text-3xl text-center mx-auto">Workforces are changing</h2>
        <p class="text-center max-w-xl mx-auto">
          COVID-19 caused a shift in how people work like never before. Because
          of this, employees are wanting more workplace benefits and healthier
          lives.
        </p>
        <div class="grid md:grid-cols-2 gap-8 md:gap-16 py-16">
          <div class="text-center flex flex-col items-center">
            <div
              class="icon-holder rounded-full flex justify-center items-center"
            >
              <ion-icon
                class="text-tertiary text-3xl"
                name="fast-food-outline"
              ></ion-icon>
            </div>
            <p class="mb-0">
              <b>50% of Australia’s workforce have poor diet</b><br />Our
              digital programs are built on practical advice to improve the
              user’s personal diet
            </p>
          </div>
          <div class="text-center flex flex-col items-center">
            <div
              class="icon-holder rounded-full flex justify-center items-center"
            >
              <ion-icon
                class="text-tertiary text-3xl"
                name="woman-outline"
              ></ion-icon>
            </div>
            <p class="mb-0">
              <b>More women are working while pregnant</b><br />We provide
              nutrition programs to support mothers and families from
              pre-conception to post-natal care
            </p>
          </div>
          <div class="text-center flex flex-col items-center">
            <div
              class="icon-holder rounded-full flex justify-center items-center"
            >
              <ion-icon
                class="text-tertiary text-3xl"
                name="calendar-outline"
              ></ion-icon>
            </div>
            <p class="mb-0">
              <b
                >More than 30% of Australians have a preventable chronic
                condition that can be managed through nutrition</b
              ><br />
              It’s these same chronic conditions that severely impact workplace
              absenteeism
            </p>
          </div>
          <div class="text-center flex flex-col items-center">
            <div
              class="icon-holder rounded-full flex justify-center items-center"
            >
              <ion-icon
                class="text-tertiary text-3xl"
                name="accessibility-outline"
              ></ion-icon>
            </div>
            <p class="mb-0">
              <b>70% of the Australian workforce are sedentary</b><br />Our
              digital programs have in-built movement reminders
            </p>
          </div>
        </div>
        <p class="text-center">
          <a
            href="../../../../assets/files/Sophus Nutrition Programs Library (June 2021).pdf"
            download
          >
            Explore the Sophus program library
            <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
          </a>
        </p>
      </div>
    </div>
  </div>

  <div class="bg-primary pt-24">
    <div class="app-container text-white text-center">
      <h2 class="text-3xl mb-6">
        Sophus’ digital solution to nutrition support is where the future is
      </h2>
      <p class="text-xl text-light max-w-xl mx-auto mb-0">
        Easily integrated into any business wellbeing program, it’s an ideal way
        to support your team’s health
      </p>
    </div>
  </div>
  <div class="app-curved-bottom-primary-background pb-24"></div>

  <div class="py-28 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-2 gap-16">
        <div class="flex justify-center items-center">
          <div class="app-blob-background-landscape">
            <img
              class="rounded-xl"
              src="../../../../assets/images/u-decide.jpg"
            />
          </div>
        </div>
        <div>
          <h2 class="text-3xl">Working with Sophus</h2>
          <p>
            The Sophus team is working closely with the dietitians and
            researchers at the Princess Alexandra hospital to develop a digital
            nutrition education program specifically to support patients with
            complex chronic medical conditions.
          </p>
          <p>
            <em>
              "Using Sophus’ technology, we’ll be able to analyse data around
              engagement and consumer preference to help shape the way we
              support to our chronic disease patients into the future."
            </em>
          </p>
          <div class="flex">
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
          </div>
          <div class="flex mt-6">
            <div class="">
              <p class="mb-0"><b>Dr Ingrid Hickman</b></p>
              <p class="mb-0">Lead researcher of the U-Decide study</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-28">
    <div class="app-container">
      <div class="max-w-sm mx-auto text-center">
        <h2 class="text-3xl">Understand how Sophus can work for your team</h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
