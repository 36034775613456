<ion-content>
  <div class="bg-light overflow-x-clip">
    <sophus-navbar></sophus-navbar>
    <div class="app-container py-2 md:pt-16 md:pb-0 lg:pt-32 xl:pt-40 xl:pb-24">
      <div class="grid md:grid-cols-2 items-center gap-16">
        <div class="">
          <h1 class="text-4xl">
            Nutrition support when your athletes need it most
          </h1>
          <p class="text-xl font-light">
            Support your athlete’s health and performance with instant
            evidence-based and practically trialled nutrition support.
          </p>
          <button
            class="rounded-md bg-primary text-white py-3 px-5 mt-4"
            appScrollTo="cta"
          >
            Book Demo
          </button>
        </div>
        <div class="flex self-stretch">
          <div class="w-full h-full app-sports-image"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-curved-right-light-background py-16"></div>

  <div class="py-36 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-2 items-center gap-8 md:gap-16 xl:gap-24">
        <div class="flex justify-center items-center relative z-10">
          <div class="app-blob-background-landscape">
            <img
              class="rounded-xl"
              src="../../../../assets/images/cycling.jpg"
            />
          </div>
        </div>
        <div class="relative z-20 order-first md:order-last">
          <h2 class="text-3xl">
            Individualised support for athletes of any level
          </h2>
          <p>
            Our diverse range of digital sports-nutrition programs are designed
            to combine evidence-based nutrition, practically trialled
            strategies, and behaviour change techniques to support your athletes
            and clients in improving their health, their performance, and their
            confidence around making food choices for optimum outcomes.
          </p>
          <p>
            <a routerLink="/experience">
              Learn more about your athelete's Sophus experience
              <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="app-curved-top-light-background pt-28"></div>
  <div class="bg-light pt-4 pb-32">
    <div class="app-container text-center">
      <h2 class="text-3xl max-w-screen-md mx-auto">
        Support your athlete’s performance and wellbeing
      </h2>
      <div class="grid md:grid-cols-3 gap-8 md:gap-24 py-16">
        <div>
          <p>
            <em>
              "The nudges and tips gave me confidence and reduced my anxiety,
              knowing I was doing the right thing for my training and helping me
              to fuel better to eat enough."
            </em>
          </p>
        </div>
        <div>
          <p>
            <em>
              “As an athlete and full time employee, I need to be able to make
              good food choices quickly and the app is the perfect resource to
              help me fuel and recover correctly throughout the day.”
            </em>
          </p>
        </div>
        <div>
          <p>
            <em>
              "I've noticed a huge improvement in how I feel and how I'm
              recovering over the last 2 weeks with the few tweaks made to my
              diet."
            </em>
          </p>
        </div>
      </div>
      <p>
        <a
          href="../../../../assets/files/Sophus Nutrition Programs Library (June 2021).pdf"
          download
        >
          Explore the Sophus program library
          <ion-icon class="-mb-1" name="chevron-forward-outline"></ion-icon>
        </a>
      </p>
    </div>
  </div>

  <div class="bg-primary pt-24">
    <div class="app-container text-white text-center">
      <h2 class="text-3xl mb-6">Nutrition advice for athletes, by athletes</h2>
      <p class="text-xl text-light max-w-3xl mx-auto mb-0">
        The sports-specific programs offered through Sophus are developed by our
        team of Accredited Sports Dietitians who have backgrounds as athletes in
        collaboration with other sporting professionals and coaches.
      </p>
    </div>
  </div>
  <div class="app-curved-bottom-primary-background pb-24"></div>

  <div class="py-28 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-2 gap-24">
        <div class="flex justify-center items-center relative z-10">
          <div class="app-blob-background">
            <img
              style="max-height: 26rem"
              class="rounded-xl"
              src="../../../../assets/images/andy-budgen.jpg"
            />
          </div>
        </div>
        <div class="relative z-20">
          <h2 class="text-3xl">Working with Sophus</h2>
          <p>
            <em>
              Partnering with Sophus has allowed me to not only be able to refer
              my athletes to a trusted nutrition professional, but also grow my
              revenue by providing more value to clients without additional
              overheads or costly professional development. Additionally, time
              is precious these days, and with more online coaching, it’s
              difficult to get time slots for everyone to come together for
              face-to-face nutrition seminars; and that can be overwhelming for
              people too. Having an innovative and engaging nutrition product to
              offer which is personalised, can be used in my clients’ own time,
              and when it suits them, is a game changer.
            </em>
          </p>
          <div class="flex">
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
            <ion-icon class="text-yellow-300" name="star"></ion-icon>
          </div>
          <div class="flex mt-6">
            <div class="">
              <p class="mb-0"><b>Andy Budgen</b></p>
              <p class="mb-0">Pursue Multisport Head Coach</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-28">
    <div class="app-container">
      <div class="max-w-sm mx-auto text-center">
        <h2 class="text-3xl">
          Understand how Sophus can work for your atheletes
        </h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
