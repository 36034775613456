import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResponse } from "@sophus/shared";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private http: HttpClient) {}

  public callback(enquiry: any): Observable<any> {
    return this.http
      .post<ApiResponse>(`/api/website/callback`, enquiry)
      .pipe(map((res) => res?.data));
  }
}
