export const TIMEZONES = [
  {
    country: "CI",
    name: "Africa/Abidjan",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "GH",
    name: "Africa/Accra",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "ET",
    name: "Africa/Addis_Ababa",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "DZ",
    name: "Africa/Algiers",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "ER",
    name: "Africa/Asmara",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "ER",
    name: "Africa/Asmera",
    status: "Deprecated",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "ML",
    name: "Africa/Bamako",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "CF",
    name: "Africa/Bangui",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "GM",
    name: "Africa/Banjul",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "GW",
    name: "Africa/Bissau",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "MW",
    name: "Africa/Blantyre",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "CG",
    name: "Africa/Brazzaville",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "BI",
    name: "Africa/Bujumbura",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "EG",
    name: "Africa/Cairo",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "MA",
    name: "Africa/Casablanca",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "ES",
    name: "Africa/Ceuta",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "GN",
    name: "Africa/Conakry",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "SN",
    name: "Africa/Dakar",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "TZ",
    name: "Africa/Dar_es_Salaam",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "DJ",
    name: "Africa/Djibouti",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "CM",
    name: "Africa/Douala",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "EH",
    name: "Africa/El_Aaiun",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "SL",
    name: "Africa/Freetown",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "BW",
    name: "Africa/Gaborone",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "ZW",
    name: "Africa/Harare",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "ZA",
    name: "Africa/Johannesburg",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "SS",
    name: "Africa/Juba",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "UG",
    name: "Africa/Kampala",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "SD",
    name: "Africa/Khartoum",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "RW",
    name: "Africa/Kigali",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "CD",
    name: "Africa/Kinshasa",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "NG",
    name: "Africa/Lagos",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "GA",
    name: "Africa/Libreville",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "TG",
    name: "Africa/Lome",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "AO",
    name: "Africa/Luanda",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "CD",
    name: "Africa/Lubumbashi",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "ZM",
    name: "Africa/Lusaka",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Maputo",
  },
  {
    country: "GQ",
    name: "Africa/Malabo",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "MZ",
    name: "Africa/Maputo",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "LS",
    name: "Africa/Maseru",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Johannesburg",
  },
  {
    country: "SZ",
    name: "Africa/Mbabane",
    status: "Alias",
    offset: "+02:00",
    link: "Africa/Johannesburg",
  },
  {
    country: "SO",
    name: "Africa/Mogadishu",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "LR",
    name: "Africa/Monrovia",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "KE",
    name: "Africa/Nairobi",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "TD",
    name: "Africa/Ndjamena",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "NE",
    name: "Africa/Niamey",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "MR",
    name: "Africa/Nouakchott",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "BF",
    name: "Africa/Ouagadougou",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "BJ",
    name: "Africa/Porto-Novo",
    status: "Alias",
    offset: "+01:00",
    link: "Africa/Lagos",
  },
  {
    country: "ST",
    name: "Africa/Sao_Tome",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "ML",
    name: "Africa/Timbuktu",
    status: "Deprecated",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "LY",
    name: "Africa/Tripoli",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "TN",
    name: "Africa/Tunis",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "NA",
    name: "Africa/Windhoek",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Adak",
    status: "Canonical",
    offset: "−10:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Anchorage",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "AI",
    name: "America/Anguilla",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "AG",
    name: "America/Antigua",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "BR",
    name: "America/Araguaina",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Buenos_Aires",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Catamarca",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/ComodRivadavia",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Catamarca",
  },
  {
    country: "AR",
    name: "America/Argentina/Cordoba",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Jujuy",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/La_Rioja",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Mendoza",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Rio_Gallegos",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Salta",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/San_Juan",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/San_Luis",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Tucuman",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Argentina/Ushuaia",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AW",
    name: "America/Aruba",
    status: "Alias",
    offset: "−04:00",
    link: "America/Curacao",
  },
  {
    country: "PY",
    name: "America/Asuncion",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Atikokan",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Atka",
    status: "Deprecated",
    offset: "−10:00",
    link: "America/Adak",
  },
  {
    country: "BR",
    name: "America/Bahia",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Bahia_Banderas",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "BB",
    name: "America/Barbados",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Belem",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "BZ",
    name: "America/Belize",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Blanc-Sablon",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Boa_Vista",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CO",
    name: "America/Bogota",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Boise",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Buenos_Aires",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Buenos_Aires",
  },
  {
    country: "CA",
    name: "America/Cambridge_Bay",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Campo_Grande",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Cancun",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "VE",
    name: "America/Caracas",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Catamarca",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Catamarca",
  },
  {
    country: "GF",
    name: "America/Cayenne",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "KY",
    name: "America/Cayman",
    status: "Alias",
    offset: "−05:00",
    link: "America/Panama",
  },
  {
    country: "US",
    name: "America/Chicago",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Chihuahua",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Coral_Harbour",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Atikokan",
  },
  {
    country: "AR",
    name: "America/Cordoba",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Cordoba",
  },
  {
    country: "CR",
    name: "America/Costa_Rica",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Creston",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Cuiaba",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CW",
    name: "America/Curacao",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "GL",
    name: "America/Danmarkshavn",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Dawson",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Dawson_Creek",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Denver",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Detroit",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "DM",
    name: "America/Dominica",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "CA",
    name: "America/Edmonton",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Eirunepe",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "SV",
    name: "America/El_Salvador",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Ensenada",
    status: "Deprecated",
    offset: "−08:00",
    link: "America/Tijuana",
  },
  {
    country: "CA",
    name: "America/Fort_Nelson",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Fort_Wayne",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Indiana/Indianapolis",
  },
  {
    country: "BR",
    name: "America/Fortaleza",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Glace_Bay",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "GL",
    name: "America/Godthab",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Nuuk",
  },
  {
    country: "CA",
    name: "America/Goose_Bay",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "TC",
    name: "America/Grand_Turk",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "GD",
    name: "America/Grenada",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "GP",
    name: "America/Guadeloupe",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "GT",
    name: "America/Guatemala",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "EC",
    name: "America/Guayaquil",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "GY",
    name: "America/Guyana",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Halifax",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CU",
    name: "America/Havana",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Hermosillo",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Indianapolis",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Knox",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Marengo",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Petersburg",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Tell_City",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Vevay",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Vincennes",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indiana/Winamac",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Indianapolis",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Indiana/Indianapolis",
  },
  {
    country: "CA",
    name: "America/Inuvik",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Iqaluit",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "JM",
    name: "America/Jamaica",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Jujuy",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Jujuy",
  },
  {
    country: "US",
    name: "America/Juneau",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Kentucky/Louisville",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Kentucky/Monticello",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Knox_IN",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Indiana/Knox",
  },
  {
    country: "BQ",
    name: "America/Kralendijk",
    status: "Alias",
    offset: "−04:00",
    link: "America/Curacao",
  },
  {
    country: "BO",
    name: "America/La_Paz",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "PE",
    name: "America/Lima",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Los_Angeles",
    status: "Canonical",
    offset: "−08:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Louisville",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Kentucky/Louisville",
  },
  {
    country: "SX",
    name: "America/Lower_Princes",
    status: "Alias",
    offset: "−04:00",
    link: "America/Curacao",
  },
  {
    country: "BR",
    name: "America/Maceio",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "NI",
    name: "America/Managua",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Manaus",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "MF",
    name: "America/Marigot",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "MQ",
    name: "America/Martinique",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Matamoros",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Mazatlan",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Mendoza",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Mendoza",
  },
  {
    country: "US",
    name: "America/Menominee",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Merida",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Metlakatla",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Mexico_City",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "PM",
    name: "America/Miquelon",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Moncton",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Monterrey",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "UY",
    name: "America/Montevideo",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Montreal",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Toronto",
  },
  {
    country: "MS",
    name: "America/Montserrat",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "BS",
    name: "America/Nassau",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/New_York",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Nipigon",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Nome",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Noronha",
    status: "Canonical",
    offset: "−02:00",
    link: "",
  },
  {
    country: "US",
    name: "America/North_Dakota/Beulah",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "US",
    name: "America/North_Dakota/Center",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "US",
    name: "America/North_Dakota/New_Salem",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "GL",
    name: "America/Nuuk",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Ojinaga",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "PA",
    name: "America/Panama",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Pangnirtung",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "SR",
    name: "America/Paramaribo",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Phoenix",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "HT",
    name: "America/Port-au-Prince",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "TT",
    name: "America/Port_of_Spain",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Porto_Acre",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Rio_Branco",
  },
  {
    country: "BR",
    name: "America/Porto_Velho",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "PR",
    name: "America/Puerto_Rico",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CL",
    name: "America/Punta_Arenas",
    status: "Canonical",
    offset: "−03:00",
    link: "Magallanes Region",
  },
  {
    country: "CA",
    name: "America/Rainy_River",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Rankin_Inlet",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Recife",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Regina",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Resolute",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Rio_Branco",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "AR",
    name: "America/Rosario",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Argentina/Cordoba",
  },
  {
    country: "MX",
    name: "America/Santa_Isabel",
    status: "Deprecated",
    offset: "−08:00",
    link: "America/Tijuana",
  },
  {
    country: "BR",
    name: "America/Santarem",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "CL",
    name: "America/Santiago",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "DO",
    name: "America/Santo_Domingo",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "BR",
    name: "America/Sao_Paulo",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "GL",
    name: "America/Scoresbysund",
    status: "Canonical",
    offset: "−01:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Shiprock",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Denver",
  },
  {
    country: "US",
    name: "America/Sitka",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "BL",
    name: "America/St_Barthelemy",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "CA",
    name: "America/St_Johns",
    status: "Canonical",
    offset: "−03:30",
    link: "",
  },
  {
    country: "KN",
    name: "America/St_Kitts",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "LC",
    name: "America/St_Lucia",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "VI",
    name: "America/St_Thomas",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "VC",
    name: "America/St_Vincent",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "CA",
    name: "America/Swift_Current",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "HN",
    name: "America/Tegucigalpa",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "GL",
    name: "America/Thule",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Thunder_Bay",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "MX",
    name: "America/Tijuana",
    status: "Canonical",
    offset: "−08:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Toronto",
    status: "Canonical",
    offset: "−05:00",
    link: "",
  },
  {
    country: "VG",
    name: "America/Tortola",
    status: "Alias",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "CA",
    name: "America/Vancouver",
    status: "Canonical",
    offset: "−08:00",
    link: "",
  },
  {
    country: "VI",
    name: "America/Virgin",
    status: "Deprecated",
    offset: "−04:00",
    link: "America/Port_of_Spain",
  },
  {
    country: "CA",
    name: "America/Whitehorse",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Winnipeg",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "US",
    name: "America/Yakutat",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "CA",
    name: "America/Yellowknife",
    status: "Canonical",
    offset: "−07:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/Casey",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/Davis",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/DumontDUrville",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AU",
    name: "Antarctica/Macquarie",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/Mawson",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/McMurdo",
    status: "Alias",
    offset: "+12:00",
    link: "Pacific/Auckland",
  },
  {
    country: "AQ",
    name: "Antarctica/Palmer",
    status: "Canonical",
    offset: "−03:00",
    link: "Chilean Antarctica Region",
  },
  {
    country: "AQ",
    name: "Antarctica/Rothera",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/South_Pole",
    status: "Deprecated",
    offset: "+12:00",
    link: "Pacific/Auckland",
  },
  {
    country: "AQ",
    name: "Antarctica/Syowa",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "AQ",
    name: "Antarctica/Troll",
    status: "Canonical",
    offset: "+00:00",
    link: "+01:00",
  },
  {
    country: "AQ",
    name: "Antarctica/Vostok",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "SJ",
    name: "Arctic/Longyearbyen",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Oslo",
  },
  {
    country: "YE",
    name: "Asia/Aden",
    status: "Alias",
    offset: "+03:00",
    link: "Asia/Riyadh",
  },
  {
    country: "KZ",
    name: "Asia/Almaty",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "JO",
    name: "Asia/Amman",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Anadyr",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "KZ",
    name: "Asia/Aqtau",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "KZ",
    name: "Asia/Aqtobe",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "TM",
    name: "Asia/Ashgabat",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "TM",
    name: "Asia/Ashkhabad",
    status: "Deprecated",
    offset: "+05:00",
    link: "Asia/Ashgabat",
  },
  {
    country: "KZ",
    name: "Asia/Atyrau",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "IQ",
    name: "Asia/Baghdad",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "BH",
    name: "Asia/Bahrain",
    status: "Alias",
    offset: "+03:00",
    link: "Asia/Qatar",
  },
  {
    country: "AZ",
    name: "Asia/Baku",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "TH",
    name: "Asia/Bangkok",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Barnaul",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "LB",
    name: "Asia/Beirut",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "KG",
    name: "Asia/Bishkek",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "BN",
    name: "Asia/Brunei",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "IN",
    name: "Asia/Calcutta",
    status: "Deprecated",
    offset: "+05:30",
    link: "Asia/Kolkata",
  },
  {
    country: "RU",
    name: "Asia/Chita",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "MN",
    name: "Asia/Choibalsan",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "CN",
    name: "Asia/Chongqing",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Shanghai",
  },
  {
    country: "CN",
    name: "Asia/Chungking",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Shanghai",
  },
  {
    country: "LK",
    name: "Asia/Colombo",
    status: "Canonical",
    offset: "+05:30",
    link: "",
  },
  {
    country: "BD",
    name: "Asia/Dacca",
    status: "Deprecated",
    offset: "+06:00",
    link: "Asia/Dhaka",
  },
  {
    country: "SY",
    name: "Asia/Damascus",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "BD",
    name: "Asia/Dhaka",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "TL",
    name: "Asia/Dili",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "AE",
    name: "Asia/Dubai",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "TJ",
    name: "Asia/Dushanbe",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "CY",
    name: "Asia/Famagusta",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "PS",
    name: "Asia/Gaza",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "CN",
    name: "Asia/Harbin",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Shanghai",
  },
  {
    country: "PS",
    name: "Asia/Hebron",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "VN",
    name: "Asia/Ho_Chi_Minh",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "HK",
    name: "Asia/Hong_Kong",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "MN",
    name: "Asia/Hovd",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Irkutsk",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "TR",
    name: "Asia/Istanbul",
    status: "Alias",
    offset: "+03:00",
    link: "Europe/Istanbul",
  },
  {
    country: "ID",
    name: "Asia/Jakarta",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "ID",
    name: "Asia/Jayapura",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "IL",
    name: "Asia/Jerusalem",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "AF",
    name: "Asia/Kabul",
    status: "Canonical",
    offset: "+04:30",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Kamchatka",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "PK",
    name: "Asia/Karachi",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "CN",
    name: "Asia/Kashgar",
    status: "Deprecated",
    offset: "+06:00",
    link: "Asia/Urumqi",
  },
  {
    country: "NP",
    name: "Asia/Kathmandu",
    status: "Canonical",
    offset: "+05:45",
    link: "",
  },
  {
    country: "NP",
    name: "Asia/Katmandu",
    status: "Deprecated",
    offset: "+05:45",
    link: "Asia/Kathmandu",
  },
  {
    country: "RU",
    name: "Asia/Khandyga",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "IN",
    name: "Asia/Kolkata",
    status: "Canonical",
    offset: "+05:30",
    link: "Time in India",
  },
  {
    country: "RU",
    name: "Asia/Krasnoyarsk",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "MY",
    name: "Asia/Kuala_Lumpur",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "MY",
    name: "Asia/Kuching",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "KW",
    name: "Asia/Kuwait",
    status: "Alias",
    offset: "+03:00",
    link: "Asia/Riyadh",
  },
  {
    country: "MO",
    name: "Asia/Macao",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Macau",
  },
  {
    country: "MO",
    name: "Asia/Macau",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Magadan",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "ID",
    name: "Asia/Makassar",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "PH",
    name: "Asia/Manila",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "OM",
    name: "Asia/Muscat",
    status: "Alias",
    offset: "+04:00",
    link: "Asia/Dubai",
  },
  {
    country: "CY",
    name: "Asia/Nicosia",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Novokuznetsk",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Novosibirsk",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Omsk",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "KZ",
    name: "Asia/Oral",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "KH",
    name: "Asia/Phnom_Penh",
    status: "Alias",
    offset: "+07:00",
    link: "Asia/Bangkok",
  },
  {
    country: "ID",
    name: "Asia/Pontianak",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "KP",
    name: "Asia/Pyongyang",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "QA",
    name: "Asia/Qatar",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "KZ",
    name: "Asia/Qostanay",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "KZ",
    name: "Asia/Qyzylorda",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "MM",
    name: "Asia/Rangoon",
    status: "Deprecated",
    offset: "+06:30",
    link: "Asia/Yangon",
  },
  {
    country: "SA",
    name: "Asia/Riyadh",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "VN",
    name: "Asia/Saigon",
    status: "Deprecated",
    offset: "+07:00",
    link: "Asia/Ho_Chi_Minh",
  },
  {
    country: "RU",
    name: "Asia/Sakhalin",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "UZ",
    name: "Asia/Samarkand",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "KR",
    name: "Asia/Seoul",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "CN",
    name: "Asia/Shanghai",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "SG",
    name: "Asia/Singapore",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Srednekolymsk",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "TW",
    name: "Asia/Taipei",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "UZ",
    name: "Asia/Tashkent",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "GE",
    name: "Asia/Tbilisi",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "IR",
    name: "Asia/Tehran",
    status: "Canonical",
    offset: "+03:30",
    link: "",
  },
  {
    country: "IL",
    name: "Asia/Tel_Aviv",
    status: "Deprecated",
    offset: "+02:00",
    link: "Asia/Jerusalem",
  },
  {
    country: "BT",
    name: "Asia/Thimbu",
    status: "Deprecated",
    offset: "+06:00",
    link: "Asia/Thimphu",
  },
  {
    country: "BT",
    name: "Asia/Thimphu",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "JP",
    name: "Asia/Tokyo",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Tomsk",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "ID",
    name: "Asia/Ujung_Pandang",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Makassar",
  },
  {
    country: "MN",
    name: "Asia/Ulaanbaatar",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "MN",
    name: "Asia/Ulan_Bator",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Ulaanbaatar",
  },
  {
    country: "CN",
    name: "Asia/Urumqi",
    status: "Canonical",
    offset: "+06:00",
    link: "Asia/Urumqi",
  },
  {
    country: "RU",
    name: "Asia/Ust-Nera",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "LA",
    name: "Asia/Vientiane",
    status: "Alias",
    offset: "+07:00",
    link: "Asia/Bangkok",
  },
  {
    country: "RU",
    name: "Asia/Vladivostok",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Yakutsk",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "MM",
    name: "Asia/Yangon",
    status: "Canonical",
    offset: "+06:30",
    link: "",
  },
  {
    country: "RU",
    name: "Asia/Yekaterinburg",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "AM",
    name: "Asia/Yerevan",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "PT",
    name: "Atlantic/Azores",
    status: "Canonical",
    offset: "−01:00",
    link: "",
  },
  {
    country: "BM",
    name: "Atlantic/Bermuda",
    status: "Canonical",
    offset: "−04:00",
    link: "",
  },
  {
    country: "ES",
    name: "Atlantic/Canary",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "CV",
    name: "Atlantic/Cape_Verde",
    status: "Canonical",
    offset: "−01:00",
    link: "",
  },
  {
    country: "FO",
    name: "Atlantic/Faeroe",
    status: "Deprecated",
    offset: "+00:00",
    link: "Atlantic/Faroe",
  },
  {
    country: "FO",
    name: "Atlantic/Faroe",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "SJ",
    name: "Atlantic/Jan_Mayen",
    status: "Deprecated",
    offset: "+01:00",
    link: "Europe/Oslo",
  },
  {
    country: "PT",
    name: "Atlantic/Madeira",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "IS",
    name: "Atlantic/Reykjavik",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "GS",
    name: "Atlantic/South_Georgia",
    status: "Canonical",
    offset: "−02:00",
    link: "",
  },
  {
    country: "SH",
    name: "Atlantic/St_Helena",
    status: "Alias",
    offset: "+00:00",
    link: "Africa/Abidjan",
  },
  {
    country: "FK",
    name: "Atlantic/Stanley",
    status: "Canonical",
    offset: "−03:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/ACT",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Sydney",
  },
  {
    country: "AU",
    name: "Australia/Adelaide",
    status: "Canonical",
    offset: "+09:30",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Brisbane",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Broken_Hill",
    status: "Canonical",
    offset: "+09:30",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Canberra",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Sydney",
  },
  {
    country: "AU",
    name: "Australia/Currie",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Hobart",
  },
  {
    country: "AU",
    name: "Australia/Darwin",
    status: "Canonical",
    offset: "+09:30",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Eucla",
    status: "Canonical",
    offset: "+08:45",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Hobart",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/LHI",
    status: "Deprecated",
    offset: "+10:30",
    link: "Australia/Lord_Howe",
  },
  {
    country: "AU",
    name: "Australia/Lindeman",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Lord_Howe",
    status: "Canonical",
    offset: "+10:30",
    link:
      "This is the only time zone in the world that uses 30-minute DST transitions.",
  },
  {
    country: "AU",
    name: "Australia/Melbourne",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/North",
    status: "Deprecated",
    offset: "+09:30",
    link: "Australia/Darwin",
  },
  {
    country: "AU",
    name: "Australia/NSW",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Sydney",
  },
  {
    country: "AU",
    name: "Australia/Perth",
    status: "Canonical",
    offset: "+08:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Queensland",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Brisbane",
  },
  {
    country: "AU",
    name: "Australia/South",
    status: "Deprecated",
    offset: "+09:30",
    link: "Australia/Adelaide",
  },
  {
    country: "AU",
    name: "Australia/Sydney",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "AU",
    name: "Australia/Tasmania",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Hobart",
  },
  {
    country: "AU",
    name: "Australia/Victoria",
    status: "Deprecated",
    offset: "+10:00",
    link: "Australia/Melbourne",
  },
  {
    country: "AU",
    name: "Australia/West",
    status: "Deprecated",
    offset: "+08:00",
    link: "Australia/Perth",
  },
  {
    country: "AU",
    name: "Australia/Yancowinna",
    status: "Deprecated",
    offset: "+09:30",
    link: "Australia/Broken_Hill",
  },
  {
    country: "BR",
    name: "Brazil/Acre",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Rio_Branco",
  },
  {
    country: "BR",
    name: "Brazil/DeNoronha",
    status: "Deprecated",
    offset: "−02:00",
    link: "America/Noronha",
  },
  {
    country: "BR",
    name: "Brazil/East",
    status: "Deprecated",
    offset: "−03:00",
    link: "America/Sao_Paulo",
  },
  {
    country: "BR",
    name: "Brazil/West",
    status: "Deprecated",
    offset: "−04:00",
    link: "America/Manaus",
  },
  {
    country: "CA",
    name: "Canada/Atlantic",
    status: "Deprecated",
    offset: "−04:00",
    link: "America/Halifax",
  },
  {
    country: "CA",
    name: "Canada/Central",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Winnipeg",
  },
  {
    country: "CA",
    name: "Canada/Eastern",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Toronto",
  },
  {
    country: "CA",
    name: "Canada/Mountain",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Edmonton",
  },
  {
    country: "CA",
    name: "Canada/Newfoundland",
    status: "Deprecated",
    offset: "−03:30",
    link: "America/St_Johns",
  },
  {
    country: "CA",
    name: "Canada/Pacific",
    status: "Deprecated",
    offset: "−08:00",
    link: "America/Vancouver",
  },
  {
    country: "CA",
    name: "Canada/Saskatchewan",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Regina",
  },
  {
    country: "CA",
    name: "Canada/Yukon",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Whitehorse",
  },
  {
    country: "",
    name: "CET",
    status: "Deprecated",
    offset: "+01:00",
    link: "Europe/Paris",
  },
  {
    country: "CL",
    name: "Chile/Continental",
    status: "Deprecated",
    offset: "−04:00",
    link: "America/Santiago",
  },
  {
    country: "CL",
    name: "Chile/EasterIsland",
    status: "Deprecated",
    offset: "−06:00",
    link: "Pacific/Easter",
  },
  {
    country: "",
    name: "CST6CDT",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Chicago",
  },
  {
    country: "CU",
    name: "Cuba",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Havana",
  },
  {
    country: "",
    name: "EET",
    status: "Deprecated",
    offset: "+02:00",
    link: "Europe/Sofia",
  },
  {
    country: "EG",
    name: "Egypt",
    status: "Deprecated",
    offset: "+02:00",
    link: "Africa/Cairo",
  },
  {
    country: "IE",
    name: "Eire",
    status: "Deprecated",
    offset: "+01:00",
    link: "Europe/Dublin",
  },
  {
    country: "",
    name: "EST",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Cancun",
  },
  {
    country: "",
    name: "EST5EDT",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/New_York",
  },
  {
    country: "",
    name: "Etc/GMT",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "",
    name: "Etc/GMT+0",
    status: "Alias",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "Etc/GMT+1",
    status: "Canonical",
    offset: "−01:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+10",
    status: "Canonical",
    offset: "−10:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+11",
    status: "Canonical",
    offset: "−11:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+12",
    status: "Canonical",
    offset: "−12:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+2",
    status: "Canonical",
    offset: "−02:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+3",
    status: "Canonical",
    offset: "−03:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+4",
    status: "Canonical",
    offset: "−04:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+5",
    status: "Canonical",
    offset: "−05:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+6",
    status: "Canonical",
    offset: "−06:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+7",
    status: "Canonical",
    offset: "−07:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+8",
    status: "Canonical",
    offset: "−08:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT+9",
    status: "Canonical",
    offset: "−09:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-0",
    status: "Alias",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "Etc/GMT-1",
    status: "Canonical",
    offset: "+01:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-10",
    status: "Canonical",
    offset: "+10:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-11",
    status: "Canonical",
    offset: "+11:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-12",
    status: "Canonical",
    offset: "+12:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-13",
    status: "Canonical",
    offset: "+13:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-14",
    status: "Canonical",
    offset: "+14:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-2",
    status: "Canonical",
    offset: "+02:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-3",
    status: "Canonical",
    offset: "+03:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-4",
    status: "Canonical",
    offset: "+04:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-5",
    status: "Canonical",
    offset: "+05:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-6",
    status: "Canonical",
    offset: "+06:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-7",
    status: "Canonical",
    offset: "+07:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-8",
    status: "Canonical",
    offset: "+08:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT-9",
    status: "Canonical",
    offset: "+09:00",
    link: "Etc area description",
  },
  {
    country: "",
    name: "Etc/GMT0",
    status: "Alias",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "Etc/Greenwich",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "Etc/UCT",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/UTC",
  },
  {
    country: "",
    name: "Etc/Universal",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/UTC",
  },
  {
    country: "",
    name: "Etc/UTC",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "",
    name: "Etc/Zulu",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/UTC",
  },
  {
    country: "NL",
    name: "Europe/Amsterdam",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "AD",
    name: "Europe/Andorra",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "RU",
    name: "Europe/Astrakhan",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "GR",
    name: "Europe/Athens",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "GB",
    name: "Europe/Belfast",
    status: "Deprecated",
    offset: "+00:00",
    link: "Europe/London",
  },
  {
    country: "RS",
    name: "Europe/Belgrade",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "DE",
    name: "Europe/Berlin",
    status: "Canonical",
    offset: "+01:00",
    link: "Time in Germany",
  },
  {
    country: "SK",
    name: "Europe/Bratislava",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Prague",
  },
  {
    country: "BE",
    name: "Europe/Brussels",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "RO",
    name: "Europe/Bucharest",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "HU",
    name: "Europe/Budapest",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "DE",
    name: "Europe/Busingen",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Zurich",
  },
  {
    country: "MD",
    name: "Europe/Chisinau",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "DK",
    name: "Europe/Copenhagen",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "IE",
    name: "Europe/Dublin",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "GI",
    name: "Europe/Gibraltar",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "GG",
    name: "Europe/Guernsey",
    status: "Alias",
    offset: "+00:00",
    link: "Europe/London",
  },
  {
    country: "FI",
    name: "Europe/Helsinki",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "IM",
    name: "Europe/Isle_of_Man",
    status: "Alias",
    offset: "+00:00",
    link: "Europe/London",
  },
  {
    country: "TR",
    name: "Europe/Istanbul",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "JE",
    name: "Europe/Jersey",
    status: "Alias",
    offset: "+00:00",
    link: "Europe/London",
  },
  {
    country: "RU",
    name: "Europe/Kaliningrad",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "UA",
    name: "Europe/Kiev",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "RU",
    name: "Europe/Kirov",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "PT",
    name: "Europe/Lisbon",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "SI",
    name: "Europe/Ljubljana",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Belgrade",
  },
  {
    country: "GB",
    name: "Europe/London",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "LU",
    name: "Europe/Luxembourg",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "ES",
    name: "Europe/Madrid",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "MT",
    name: "Europe/Malta",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "AX",
    name: "Europe/Mariehamn",
    status: "Alias",
    offset: "+02:00",
    link: "Europe/Helsinki",
  },
  {
    country: "BY",
    name: "Europe/Minsk",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "MC",
    name: "Europe/Monaco",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "RU",
    name: "Europe/Moscow",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "CY",
    name: "Europe/Nicosia",
    status: "Alias",
    offset: "+02:00",
    link: "Asia/Nicosia",
  },
  {
    country: "NO",
    name: "Europe/Oslo",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "FR",
    name: "Europe/Paris",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "ME",
    name: "Europe/Podgorica",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Belgrade",
  },
  {
    country: "CZ",
    name: "Europe/Prague",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "LV",
    name: "Europe/Riga",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "IT",
    name: "Europe/Rome",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "RU",
    name: "Europe/Samara",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "SM",
    name: "Europe/San_Marino",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Rome",
  },
  {
    country: "BA",
    name: "Europe/Sarajevo",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Belgrade",
  },
  {
    country: "RU",
    name: "Europe/Saratov",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "UA",
    name: "Europe/Simferopol",
    status: "Canonical",
    offset: "+03:00",
    link: "Disputed - Reflects data in the TZDB.",
  },
  {
    country: "MK",
    name: "Europe/Skopje",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Belgrade",
  },
  {
    country: "BG",
    name: "Europe/Sofia",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "SE",
    name: "Europe/Stockholm",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "EE",
    name: "Europe/Tallinn",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "AL",
    name: "Europe/Tirane",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "MD",
    name: "Europe/Tiraspol",
    status: "Deprecated",
    offset: "+02:00",
    link: "Europe/Chisinau",
  },
  {
    country: "RU",
    name: "Europe/Ulyanovsk",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "UA",
    name: "Europe/Uzhgorod",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "LI",
    name: "Europe/Vaduz",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Zurich",
  },
  {
    country: "VA",
    name: "Europe/Vatican",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Rome",
  },
  {
    country: "AT",
    name: "Europe/Vienna",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "LT",
    name: "Europe/Vilnius",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "RU",
    name: "Europe/Volgograd",
    status: "Canonical",
    offset: "+03:00",
    link: "",
  },
  {
    country: "PL",
    name: "Europe/Warsaw",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "HR",
    name: "Europe/Zagreb",
    status: "Alias",
    offset: "+01:00",
    link: "Europe/Belgrade",
  },
  {
    country: "UA",
    name: "Europe/Zaporozhye",
    status: "Canonical",
    offset: "+02:00",
    link: "",
  },
  {
    country: "CH",
    name: "Europe/Zurich",
    status: "Canonical",
    offset: "+01:00",
    link: "",
  },
  {
    country: "",
    name: "Factory",
    status: "Canonical",
    offset: "+00:00",
    link: "",
  },
  {
    country: "GB",
    name: "GB",
    status: "Deprecated",
    offset: "+00:00",
    link: "Europe/London",
  },
  {
    country: "GB",
    name: "GB-Eire",
    status: "Deprecated",
    offset: "+00:00",
    link: "Europe/London",
  },
  {
    country: "",
    name: "GMT",
    status: "Alias",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "GMT+0",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "GMT-0",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "GMT0",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "",
    name: "Greenwich",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/GMT",
  },
  {
    country: "HK",
    name: "Hongkong",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Hong_Kong",
  },
  {
    country: "",
    name: "HST",
    status: "Deprecated",
    offset: "−10:00",
    link: "Pacific/Honolulu",
  },
  {
    country: "IS",
    name: "Iceland",
    status: "Deprecated",
    offset: "+00:00",
    link: "Atlantic/Reykjavik",
  },
  {
    country: "MG",
    name: "Indian/Antananarivo",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "IO",
    name: "Indian/Chagos",
    status: "Canonical",
    offset: "+06:00",
    link: "",
  },
  {
    country: "CX",
    name: "Indian/Christmas",
    status: "Canonical",
    offset: "+07:00",
    link: "",
  },
  {
    country: "CC",
    name: "Indian/Cocos",
    status: "Canonical",
    offset: "+06:30",
    link: "",
  },
  {
    country: "KM",
    name: "Indian/Comoro",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "TF",
    name: "Indian/Kerguelen",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "SC",
    name: "Indian/Mahe",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "MV",
    name: "Indian/Maldives",
    status: "Canonical",
    offset: "+05:00",
    link: "",
  },
  {
    country: "MU",
    name: "Indian/Mauritius",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "YT",
    name: "Indian/Mayotte",
    status: "Alias",
    offset: "+03:00",
    link: "Africa/Nairobi",
  },
  {
    country: "RE",
    name: "Indian/Reunion",
    status: "Canonical",
    offset: "+04:00",
    link: "",
  },
  {
    country: "IR",
    name: "Iran",
    status: "Deprecated",
    offset: "+03:30",
    link: "Asia/Tehran",
  },
  {
    country: "IL",
    name: "Israel",
    status: "Deprecated",
    offset: "+02:00",
    link: "Asia/Jerusalem",
  },
  {
    country: "JM",
    name: "Jamaica",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Jamaica",
  },
  {
    country: "JP",
    name: "Japan",
    status: "Deprecated",
    offset: "+09:00",
    link: "Asia/Tokyo",
  },
  {
    country: "MH",
    name: "Kwajalein",
    status: "Deprecated",
    offset: "+12:00",
    link: "Pacific/Kwajalein",
  },
  {
    country: "LY",
    name: "Libya",
    status: "Deprecated",
    offset: "+02:00",
    link: "Africa/Tripoli",
  },
  {
    country: "",
    name: "MET",
    status: "Deprecated",
    offset: "+01:00",
    link: "Europe/Paris",
  },
  {
    country: "MX",
    name: "Mexico/BajaNorte",
    status: "Deprecated",
    offset: "−08:00",
    link: "America/Tijuana",
  },
  {
    country: "MX",
    name: "Mexico/BajaSur",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Mazatlan",
  },
  {
    country: "MX",
    name: "Mexico/General",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Mexico_City",
  },
  {
    country: "",
    name: "MST",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Phoenix",
  },
  {
    country: "",
    name: "MST7MDT",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Denver",
  },
  {
    country: "US",
    name: "Navajo",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Denver",
  },
  {
    country: "NZ",
    name: "NZ",
    status: "Deprecated",
    offset: "+12:00",
    link: "Pacific/Auckland",
  },
  {
    country: "NZ",
    name: "NZ-CHAT",
    status: "Deprecated",
    offset: "+12:45",
    link: "Pacific/Chatham",
  },
  {
    country: "WS",
    name: "Pacific/Apia",
    status: "Canonical",
    offset: "+13:00",
    link: "",
  },
  {
    country: "NZ",
    name: "Pacific/Auckland",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "PG",
    name: "Pacific/Bougainville",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "NZ",
    name: "Pacific/Chatham",
    status: "Canonical",
    offset: "+12:45",
    link: "",
  },
  {
    country: "FM",
    name: "Pacific/Chuuk",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "CL",
    name: "Pacific/Easter",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "VU",
    name: "Pacific/Efate",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "KI",
    name: "Pacific/Enderbury",
    status: "Canonical",
    offset: "+13:00",
    link: "",
  },
  {
    country: "TK",
    name: "Pacific/Fakaofo",
    status: "Canonical",
    offset: "+13:00",
    link: "",
  },
  {
    country: "FJ",
    name: "Pacific/Fiji",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "TV",
    name: "Pacific/Funafuti",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "EC",
    name: "Pacific/Galapagos",
    status: "Canonical",
    offset: "−06:00",
    link: "",
  },
  {
    country: "PF",
    name: "Pacific/Gambier",
    status: "Canonical",
    offset: "−09:00",
    link: "",
  },
  {
    country: "SB",
    name: "Pacific/Guadalcanal",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "GU",
    name: "Pacific/Guam",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "US",
    name: "Pacific/Honolulu",
    status: "Canonical",
    offset: "−10:00",
    link: "",
  },
  {
    country: "UM",
    name: "Pacific/Johnston",
    status: "Deprecated",
    offset: "−10:00",
    link: "Pacific/Honolulu",
  },
  {
    country: "KI",
    name: "Pacific/Kiritimati",
    status: "Canonical",
    offset: "+14:00",
    link: "",
  },
  {
    country: "FM",
    name: "Pacific/Kosrae",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "MH",
    name: "Pacific/Kwajalein",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "MH",
    name: "Pacific/Majuro",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "PF",
    name: "Pacific/Marquesas",
    status: "Canonical",
    offset: "−09:30",
    link: "",
  },
  {
    country: "UM",
    name: "Pacific/Midway",
    status: "Alias",
    offset: "−11:00",
    link: "Pacific/Pago_Pago",
  },
  {
    country: "NR",
    name: "Pacific/Nauru",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "NU",
    name: "Pacific/Niue",
    status: "Canonical",
    offset: "−11:00",
    link: "",
  },
  {
    country: "NF",
    name: "Pacific/Norfolk",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "NC",
    name: "Pacific/Noumea",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "AS",
    name: "Pacific/Pago_Pago",
    status: "Canonical",
    offset: "−11:00",
    link: "",
  },
  {
    country: "PW",
    name: "Pacific/Palau",
    status: "Canonical",
    offset: "+09:00",
    link: "",
  },
  {
    country: "PN",
    name: "Pacific/Pitcairn",
    status: "Canonical",
    offset: "−08:00",
    link: "",
  },
  {
    country: "FM",
    name: "Pacific/Pohnpei",
    status: "Canonical",
    offset: "+11:00",
    link: "",
  },
  {
    country: "FM",
    name: "Pacific/Ponape",
    status: "Deprecated",
    offset: "+11:00",
    link: "Pacific/Pohnpei",
  },
  {
    country: "PG",
    name: "Pacific/Port_Moresby",
    status: "Canonical",
    offset: "+10:00",
    link: "",
  },
  {
    country: "CK",
    name: "Pacific/Rarotonga",
    status: "Canonical",
    offset: "−10:00",
    link: "",
  },
  {
    country: "MP",
    name: "Pacific/Saipan",
    status: "Alias",
    offset: "+10:00",
    link: "Pacific/Guam",
  },
  {
    country: "WS",
    name: "Pacific/Samoa",
    status: "Deprecated",
    offset: "−11:00",
    link: "Pacific/Pago_Pago",
  },
  {
    country: "PF",
    name: "Pacific/Tahiti",
    status: "Canonical",
    offset: "−10:00",
    link: "",
  },
  {
    country: "KI",
    name: "Pacific/Tarawa",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "TO",
    name: "Pacific/Tongatapu",
    status: "Canonical",
    offset: "+13:00",
    link: "",
  },
  {
    country: "FM",
    name: "Pacific/Truk",
    status: "Deprecated",
    offset: "+10:00",
    link: "Pacific/Chuuk",
  },
  {
    country: "UM",
    name: "Pacific/Wake",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "WF",
    name: "Pacific/Wallis",
    status: "Canonical",
    offset: "+12:00",
    link: "",
  },
  {
    country: "FM",
    name: "Pacific/Yap",
    status: "Deprecated",
    offset: "+10:00",
    link: "Pacific/Chuuk",
  },
  {
    country: "PL",
    name: "Poland",
    status: "Deprecated",
    offset: "+01:00",
    link: "Europe/Warsaw",
  },
  {
    country: "PT",
    name: "Portugal",
    status: "Deprecated",
    offset: "+00:00",
    link: "Europe/Lisbon",
  },
  {
    country: "CN",
    name: "PRC",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Shanghai",
  },
  {
    country: "",
    name: "PST8PDT",
    status: "Deprecated",
    offset: "−08:00",
    link: "America/Los_Angeles",
  },
  {
    country: "TW",
    name: "ROC",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Taipei",
  },
  {
    country: "KR",
    name: "ROK",
    status: "Deprecated",
    offset: "+09:00",
    link: "Asia/Seoul",
  },
  {
    country: "SG",
    name: "Singapore",
    status: "Deprecated",
    offset: "+08:00",
    link: "Asia/Singapore",
  },
  {
    country: "TR",
    name: "Turkey",
    status: "Deprecated",
    offset: "+03:00",
    link: "Europe/Istanbul",
  },
  {
    country: "",
    name: "UCT",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/UTC",
  },
  {
    country: "",
    name: "Universal",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/UTC",
  },
  {
    country: "US",
    name: "US/Alaska",
    status: "Deprecated",
    offset: "−09:00",
    link: "America/Anchorage",
  },
  {
    country: "US",
    name: "US/Aleutian",
    status: "Deprecated",
    offset: "−10:00",
    link: "America/Adak",
  },
  {
    country: "US",
    name: "US/Arizona",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Phoenix",
  },
  {
    country: "US",
    name: "US/Central",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Chicago",
  },
  {
    country: "US",
    name: "US/East-Indiana",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Indiana/Indianapolis",
  },
  {
    country: "US",
    name: "US/Eastern",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/New_York",
  },
  {
    country: "US",
    name: "US/Hawaii",
    status: "Deprecated",
    offset: "−10:00",
    link: "Pacific/Honolulu",
  },
  {
    country: "US",
    name: "US/Indiana-Starke",
    status: "Deprecated",
    offset: "−06:00",
    link: "America/Indiana/Knox",
  },
  {
    country: "US",
    name: "US/Michigan",
    status: "Deprecated",
    offset: "−05:00",
    link: "America/Detroit",
  },
  {
    country: "US",
    name: "US/Mountain",
    status: "Deprecated",
    offset: "−07:00",
    link: "America/Denver",
  },
  {
    country: "US",
    name: "US/Pacific",
    status: "Deprecated",
    offset: "−08:00",
    link: "America/Los_Angeles",
  },
  {
    country: "WS",
    name: "US/Samoa",
    status: "Deprecated",
    offset: "−11:00",
    link: "Pacific/Pago_Pago",
  },
  {
    country: "",
    name: "UTC",
    status: "Alias",
    offset: "+00:00",
    link: "Etc/UTC",
  },
  {
    country: "RU",
    name: "W-SU",
    status: "Deprecated",
    offset: "+03:00",
    link: "Europe/Moscow",
  },
  {
    country: "",
    name: "WET",
    status: "Deprecated",
    offset: "+00:00",
    link: "Europe/Lisbon",
  },
  {
    country: "",
    name: "Zulu",
    status: "Deprecated",
    offset: "+00:00",
    link: "Etc/UTC",
  },
];

export const TIMEZONESFULL = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    text: "(UTC-12:00) International Date Line West",
    utc: ["Etc/GMT+12"],
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"],
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    utc: [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti",
    ],
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -8,
    isdst: true,
    text: "(UTC-09:00) Alaska",
    utc: [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
    ],
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-08:00) Baja California",
    utc: ["America/Santa_Isabel"],
  },
  {
    value: "Pacific Daylight Time",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Pacific Time (US & Canada)",
    utc: [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
    ],
  },
  {
    value: "Pacific Standard Time",
    abbr: "PST",
    offset: -8,
    isdst: false,
    text: "(UTC-08:00) Pacific Time (US & Canada)",
    utc: [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "PST8PDT",
    ],
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    utc: [
      "America/Creston",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "Etc/GMT+7",
    ],
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    utc: ["America/Chihuahua", "America/Mazatlan"],
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT",
    ],
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Central America",
    utc: [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos",
    ],
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT",
    ],
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    utc: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
    ],
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Saskatchewan",
    utc: ["America/Regina", "America/Swift_Current"],
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    utc: [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5",
    ],
  },
  {
    value: "Eastern Standard Time",
    abbr: "EDT",
    offset: -4,
    isdst: true,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT",
    ],
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -4,
    isdst: true,
    text: "(UTC-05:00) Indiana (East)",
    utc: [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis",
    ],
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    text: "(UTC-04:30) Caracas",
    utc: ["America/Caracas"],
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PYT",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    utc: ["America/Asuncion"],
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -3,
    isdst: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    utc: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda",
    ],
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    utc: ["America/Campo_Grande", "America/Cuiaba"],
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    utc: [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4",
    ],
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Santiago",
    utc: ["America/Santiago", "Antarctica/Palmer"],
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -2.5,
    isdst: true,
    text: "(UTC-03:30) Newfoundland",
    utc: ["America/St_Johns"],
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Brasilia",
    utc: ["America/Sao_Paulo"],
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    utc: [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza",
    ],
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    utc: [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3",
    ],
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -3,
    isdst: true,
    text: "(UTC-03:00) Greenland",
    utc: ["America/Godthab"],
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Montevideo",
    utc: ["America/Montevideo"],
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Salvador",
    utc: ["America/Bahia"],
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -1,
    isdst: true,
    text: "(UTC-02:00) Mid-Atlantic - Old",
    utc: [],
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: 0,
    isdst: true,
    text: "(UTC-01:00) Azores",
    utc: ["America/Scoresbysund", "Atlantic/Azores"],
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    text: "(UTC-01:00) Cape Verde Is.",
    utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Casablanca",
    utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
  },
  {
    value: "UTC",
    abbr: "UTC",
    offset: 0,
    isdst: false,
    text: "(UTC) Coordinated Universal Time",
    utc: ["America/Danmarkshavn", "Etc/GMT"],
  },
  {
    value: "GMT Standard Time",
    abbr: "GMT",
    offset: 0,
    isdst: false,
    text: "(UTC) Edinburgh, London",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "British Summer Time",
    abbr: "BST",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Edinburgh, London",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Dublin, Lisbon",
    utc: [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon",
    ],
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    text: "(UTC) Monrovia, Reykjavik",
    utc: [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena",
    ],
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    utc: [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich",
    ],
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    utc: [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane",
    ],
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    utc: [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris",
    ],
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"],
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) West Central Africa",
    utc: [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1",
    ],
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) Windhoek",
    utc: ["Africa/Windhoek"],
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Athens, Bucharest",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
    ],
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Beirut",
    utc: ["Asia/Beirut"],
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Cairo",
    utc: ["Africa/Cairo"],
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Damascus",
    utc: ["Asia/Damascus"],
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) E. Europe",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Harare, Pretoria",
    utc: [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2",
    ],
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    utc: [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Istanbul",
    utc: ["Europe/Istanbul"],
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Jerusalem",
    utc: ["Asia/Jerusalem"],
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Tripoli",
    utc: ["Africa/Tripoli"],
  },
  {
    value: "Jordan Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Amman",
    utc: ["Asia/Amman"],
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Baghdad",
    utc: ["Asia/Baghdad"],
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    text: "(UTC+02:00) Kaliningrad",
    utc: ["Europe/Kaliningrad"],
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    utc: [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh",
    ],
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Nairobi",
    utc: [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte",
    ],
  },
  {
    value: "Moscow Standard Time",
    abbr: "MSK",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    utc: [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk",
    ],
  },
  {
    value: "Samara Time",
    abbr: "SAMT",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 4.5,
    isdst: true,
    text: "(UTC+03:30) Tehran",
    utc: ["Asia/Tehran"],
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 5,
    isdst: true,
    text: "(UTC+04:00) Baku",
    utc: ["Asia/Baku"],
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Port Louis",
    utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
  },
  {
    value: "Georgian Standard Time",
    abbr: "GET",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Tbilisi",
    utc: ["Asia/Tbilisi"],
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Yerevan",
    utc: ["Asia/Yerevan"],
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    text: "(UTC+04:30) Kabul",
    utc: ["Asia/Kabul"],
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    utc: [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives",
    ],
  },
  {
    value: "Yekaterinburg Time",
    abbr: "YEKT",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Yekaterinburg",
    utc: ["Asia/Yekaterinburg"],
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PKT",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    utc: ["Asia/Karachi"],
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    utc: ["Asia/Kolkata"],
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    utc: ["Asia/Colombo"],
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    text: "(UTC+05:45) Kathmandu",
    utc: ["Asia/Kathmandu"],
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Nur-Sultan (Astana)",
    utc: [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos",
    ],
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Dhaka",
    utc: ["Asia/Dhaka", "Asia/Thimphu"],
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    utc: ["Asia/Rangoon", "Indian/Cocos"],
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    utc: [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas",
    ],
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Novosibirsk",
    utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Krasnoyarsk",
    utc: ["Asia/Krasnoyarsk"],
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    utc: [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8",
    ],
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Perth",
    utc: ["Antarctica/Casey", "Australia/Perth"],
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Taipei",
    utc: ["Asia/Taipei"],
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Ulaanbaatar",
    utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Irkutsk",
    utc: ["Asia/Irkutsk"],
  },
  {
    value: "Japan Standard Time",
    abbr: "JST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    utc: [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau",
    ],
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Seoul",
    utc: ["Asia/Pyongyang", "Asia/Seoul"],
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Adelaide",
    utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Darwin",
    utc: ["Australia/Darwin"],
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Brisbane",
    utc: ["Australia/Brisbane", "Australia/Lindeman"],
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    utc: ["Australia/Melbourne", "Australia/Sydney"],
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    utc: [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk",
    ],
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Hobart",
    utc: ["Australia/Currie", "Australia/Hobart"],
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Yakutsk",
    utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    utc: [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape",
    ],
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Vladivostok",
    utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Auckland, Wellington",
    utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    utc: [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis",
    ],
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Fiji",
    utc: ["Pacific/Fiji"],
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Magadan",
    utc: [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk",
    ],
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    utc: ["Asia/Kamchatka"],
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Nuku'alofa",
    utc: [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu",
    ],
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Samoa",
    utc: ["Pacific/Apia"],
  },
];
