import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  private url: string;

  constructor(private title: Title, private meta: Meta) {
    this.url = "https://sophus.com.au";
  }

  public updateTitle(title: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({ name: "og:title", content: title });
  }

  public updateDescription(desc: string): void {
    this.meta.updateTag({ name: "description", content: desc });
  }

  public updateUrl(url: string): void {
    this.meta.updateTag({ name: "og:url", content: this.url + url });
  }

  public updateImage(url: string): void {
    this.meta.updateTag({ name: "og:image", content: this.url + url });
  }
}
