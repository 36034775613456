import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";
import { IonContent } from "@ionic/angular";

@Directive({
  selector: "[appScrollTo]",
})
export class ScrollToDirective implements AfterViewInit {
  private el: ElementRef;
  private content?: any;

  @Input()
  public appScrollTo: string;

  constructor(el: ElementRef, private renderer: Renderer2) {
    this.appScrollTo = "";
    this.el = el;
  }

  public ngAfterViewInit(): void {
    this.content = this.el.nativeElement.closest("ion-content");
  }

  @HostListener("click")
  public async onClick() {
    if (!this.content) return;
    const target = this.content.querySelectorAll(`#${this.appScrollTo}`)[0];
    if (!target?.offsetTop) return;
    this.content.scrollToPoint(0, target.offsetTop, 1000);
  }
}
