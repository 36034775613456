import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "sophus-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  public partnerLogos: any;
  public hidden: boolean;

  constructor(private titleService: Title) {
    this.hidden = true;
  }

  ngOnInit(): void {
    this.partnerLogos = ["advance.svg", "yep-logo.png", "hla.svg", "fly.svg"];
  }
}
