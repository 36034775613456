import { Injectable } from "@angular/core";

export interface Environment {
  environment: string;
  development?: boolean;
  url?: string;
  app?: string;
  name?: string;
  orgId?: string;
  websiteUrl?: string;
  termsUrl?: string;
  privacyUrl?: string;
  phoneUrl?: string;
  appStoreUrl?: string;
  playStoreUrl?: string;
  supportUrl?: string;
  email?: string;
}

@Injectable()
export class Environment implements Environment {}
