import { CommonModule } from "@angular/common";
import { ErrorHandler, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { NotifyLoadingWidgetComponent } from "./components/notify-loading-widget/notify-loading-widget.component";
import { GlobalErrorHandler } from "./global-error-handler";
import { NotifyLibService } from "./services/notify/notify-lib.service";

@NgModule({
  imports: [CommonModule, IonicModule],
  providers: [
    NotifyLibService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  declarations: [NotifyLoadingWidgetComponent],
  exports: [NotifyLoadingWidgetComponent],
})
export class NotifyLibModule {}
