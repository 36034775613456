<div class="app-footer">
  <div class="app-curved-left-primary-background pt-16 lg:pt-32"></div>
  <div class="bg-primary">
    <div class="app-container relative z-10">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-6 xl:col-span-3 lg:order-1">
          <a href="/" routerLink="/">
            <img
              class="m-auto xl:m-0 pb-8"
              src="../../../../assets/images/logo-light.svg"
              alt="Sophus logo white"
            />
          </a>
        </div>
        <div
          class="text-center col-span-12 lg:col-span-6 xl:col-span-3 lg:order-2 xl:text-left"
        >
          <p>
            <a class="text-white" href="/medical" routerLink="/medical">
              Health Care Providers
            </a>
          </p>
          <p>
            <a class="text-white" href="/employers" routerLink="/employers">
              Employers
            </a>
          </p>
          <p>
            <a class="text-white" href="/sports" routerLink="/sports">
              Sports Professionals
            </a>
          </p>
        </div>
        <div
          class="text-center col-span-12 lg:col-span-6 xl:col-span-3 lg:order-4 xl:order-3 xl:text-left"
        >
          <p>
            <a class="text-white" href="/experience" routerLink="/experience">
              Client Experience
            </a>
          </p>
          <p>
            <a class="text-white" href="/about" routerLink="/about">
              About Us
            </a>
          </p>
        </div>
        <div
          class="text-center col-span-12 lg:col-span-6 xl:col-span-3 lg:order-3 xl:order-4 xl:text-center"
        >
          <p class="text-3xl">
            <a
              class="text-white m-1"
              target="_blank"
              href="https://www.facebook.com/sophusnutrition/"
            >
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
            <a
              class="text-white m-1"
              target="_blank"
              href="https://twitter.com/NutritionSophus"
            >
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
            <a
              class="text-white m-1"
              target="_blank"
              href="https://www.linkedin.com/company/sophus-nutrition-pty-ltd/"
            >
              <ion-icon name="logo-linkedin"></ion-icon
            ></a>
            <a
              class="text-white m-1"
              target="_blank"
              href="https://www.instagram.com/sophusnutrition/"
            >
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </p>
          <div class="">
            <a
              class="m-1"
              href="https://apps.apple.com/us/app/sophus-nutrition/id1474937545"
              target="_blank"
            >
              <img
                class="h-full w-auto inline-block"
                src="../../../../assets/images/apple-logo.svg"
                alt="Apple app store logo"
              />
            </a>
            <a
              class="m-1"
              href="https://play.google.com/store/apps/details?hl=en&id=com.sophusnutrition.sophus"
              target="_blank"
            >
              <img
                class="h-full w-auto inline-block"
                src="../../../../assets/images/android-logo.svg"
                alt="Google play store logo"
              />
            </a>
          </div>
        </div>
        <div class="text-center col-span-12 lg:order-5 mb-8 lg:my-8">
          <p class="text-lightgrey text-sm mt-8">
            © 2021 Sophus Health Pty Ltd
          </p>
          <p class="text-sm xl:m-0">
            <a class="text-lightgrey" href="/terms" routerLink="/terms">
              Terms of Service
            </a>
            <a
              class="text-lightgrey ml-4"
              href="/privacy"
              routerLink="/privacy"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
