import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { AboutComponent } from "./components/about/about.component";
import { ClientsComponent } from "./components/clients/clients.component";
import { EmployersComponent } from "./components/employers/employers.component";
import { HomeComponent } from "./components/home/home.component";
import { MedicalComponent } from "./components/medical/medical.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { SportsComponent } from "./components/sports/sports.component";
import { TermsComponent } from "./components/terms/terms.component";

@NgModule({
  declarations: [
    AboutComponent,
    HomeComponent,
    MedicalComponent,
    SportsComponent,
    EmployersComponent,
    ClientsComponent,
    PrivacyComponent,
    TermsComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [],
})
export class PagesModule {}
