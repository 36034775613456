<ion-content>
  <div class="pt-8 pb-4">
    <div class="app-container">
      <div class="flex items-center justify-end">
        <div>
          <ion-icon
            (click)="closeMobileMenu()"
            class="text-3xl"
            name="close-outline"
          ></ion-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ul class="uppercase text-center">
      <li class="py-3 px-4">
        <a class="" (click)="closeMobileMenu()" routerLink="/">Home</a>
      </li>
      <li class="py-3 px-4">
        <a class="" (click)="closeMobileMenu()" routerLink="/medical">
          Health Care Providers
        </a>
      </li>
      <li class="py-3 px-4">
        <a class="" (click)="closeMobileMenu()" routerLink="/employers">
          Employers
        </a>
      </li>
      <li class="py-3 px-4">
        <a class="" (click)="closeMobileMenu()" routerLink="/sports">
          Sports Professionals
        </a>
      </li>
      <li class="py-3 px-4">
        <a class="" (click)="closeMobileMenu()" routerLink="/experience">
          Client Experience
        </a>
      </li>
      <li class="py-3 px-4">
        <a class="" (click)="closeMobileMenu()" routerLink="/about">
          About Us
        </a>
      </li>
      <li class="py-3 px-4">
        <a
          class=""
          (click)="closeMobileMenu()"
          href="https://research.sophus.com.au"
          target="_blank"
        >
          Research
        </a>
      </li>
    </ul>
    <div class="text-center">
      <p class="text-3xl mt-8">
        <a
          class="m-1"
          target="_blank"
          href="https://www.facebook.com/sophusnutrition/"
        >
          <ion-icon name="logo-facebook"></ion-icon>
        </a>
        <a
          class="m-1"
          target="_blank"
          href="https://twitter.com/NutritionSophus"
        >
          <ion-icon name="logo-twitter"></ion-icon>
        </a>
        <a
          class="m-1"
          target="_blank"
          href="https://www.linkedin.com/company/sophus-nutrition-pty-ltd/"
        >
          <ion-icon name="logo-linkedin"></ion-icon
        ></a>
        <a
          class="m-1"
          target="_blank"
          href="https://www.instagram.com/sophusnutrition/"
        >
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
      </p>
      <p class="text-lightgrey text-sm mt-8">© 2021 Sophus Health Pty Ltd</p>
      <p class="text-sm xl:m-0">
        <a class="text-lightgrey" href="/terms" routerLink="/terms">
          Terms of Service
        </a>
        <a class="text-lightgrey ml-4" href="/privacy" routerLink="/privacy">
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
</ion-content>
