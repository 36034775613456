import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "sophus-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  constructor(private router: Router) {}

  public link(route: string): void {
    this.router.navigateByUrl(route);
  }
}
