<ion-content color="light">
  <div class="app-flex">
    <div class="bg-light overflow-x-clip flex-grow">
      <sophus-navbar></sophus-navbar>
      <div
        class="app-container py-2 md:pt-16 md:pb-0 lg:pt-32 xl:pt-40 xl:pb-24"
      >
        <div class="grid md:grid-cols-2 items-center gap-24">
          <div class="">
            <h1 class="text-4xl">Uh oh!</h1>
            <p class="text-xl font-light">
              We can't seem to find the page your looking for. Maybe try
              starting from the home page?
            </p>
            <p>
              <a href="/" routerLink="/">
                Go back to home page
                <ion-icon
                  class="-mb-1"
                  name="chevron-forward-outline"
                ></ion-icon>
              </a>
            </p>
          </div>
          <div class="flex self-stretch">
            <div class="w-full h-full app-employers-image"></div>
          </div>
        </div>
      </div>
    </div>

    <sophus-footer></sophus-footer>
  </div>
</ion-content>
