<ion-content>
  <div class="bg-light">
    <sophus-navbar></sophus-navbar>
  </div>
  <div class="app-curved-right-light-background py-4"></div>

  <div class="py-28">
    <div class="max-w-2xl mx-auto">
      <div class="">
        <h2 class="text-3xl">Privacy Policy (EU & worldwide)</h2>
        <p>
          Health Management Pty Ltd T/A Sophus Nutrition (Sophus Nutrition)
          takes your privacy very seriously and treats all your personal data
          with great care. This document sets out Sophus Nutrition’s policy
          regarding privacy and security. It is recommended that you read this
          policy carefully. The capitalized words are defined in the Terms of
          Service.
        </p>
        <h3 class="text-2xl">1. Who is Sophus Nutrition?</h3>
        <p>
          Sophus Nutrition is registered under Health Management Pty Ltd;
          established and existing under the laws of Australia, having its
          registered office at 4/194 McLeod St, Cairns, QLD, 4870, AUS, and
          active on the website of www.sophus.com.au.
        </p>
        <p>
          Sophus Nutrition has developed a platform used by healthcare providers
          to gather information from, and/or provide information to their
          patients. Sophus Nutrition does not screen Content posted by
          healthcare providers, nor does it select or screen specific programs
          that are displayed to patients.
        </p>
        <p>
          <em
            >Sophus Nutrition as processor on behalf of healthcare providers</em
          >
        </p>
        <p>
          Sophus Nutrition will store and process your personal data on behalf
          of its customers, the healthcare providers. For this processing, the
          healthcare provider will act as the “data controller” within the
          meaning of the European Privacy Directive (1995/46) and the Data
          Protection Act 1998 and is responsible for the lawful processing of
          your personal data. Please refer to your healthcare provider for
          information on the way the healthcare provider will process your
          personal data. Whilst Sophus Nutrition takes the protection of
          personal data very seriously, Sophus Nutrition is not responsible for
          the compliance with applicable privacy laws by the healthcare
          provider.
        </p>
        <p>Sophus Nutrition as controller</p>
        <p>
          In certain circumstances Sophus Nutrition may also process your
          personal data for its own purposes, in which case Sophus Nutrition
          will be the “data controller” of your personal data and responsible
          for the lawful processing of this personal data. Sophus Nutrition is
          the controller for the processing of payments by healthcare providers,
          the processing of account information, the use of Intercom cookies as
          set out below (under 3) and the provision of aggregated information to
          authorised parties (with your consent).
        </p>
        <h3 class="text-2xl">
          2. What personal data does Sophus Nutrition collect and process?
        </h3>
        <p><em>Account</em></p>
        <p>
          In order to make use of the Service, it is necessary to create a
          personal Account. For this you are required to enter certain
          information about yourself. Your name, gender, e-mail address, phone
          number and country of residence are obligatory. For healthcare
          providers who register on behalf of an entity, information about that
          entity (name and contact details) are also required.
        </p>
        <p>
          The information contained in your account is not visible to third
          parties. For patients, only the healthcare provider that has sent them
          an invitation to use the Service can see their Account information.
        </p>
        <p>
          A detailed list of data that is processed by Sophus Nutrition can be
          found here.
        </p>
        <p>
          <em>Use of the Service</em>
        </p>
        <p>
          By using the Service, the healthcare provider provides information
          about the exercise program of the patients and the patients provide
          information about their compliance with the exercise program and their
          experiences while doing the exercises. This information is private
          between the patient and the healthcare provider. Sophus Nutrition will
          store and process this information only on behalf of the healthcare
          provider. Sophus Nutrition will only use the patient information for
          its own purposes with the consent of the patient. After the patient
          has given his consent, Sophus Nutrition will anonymise the patient
          information and share it with authorised third parties.
        </p>
        <p>
          If the patient is a minor, the parents or legal guardians of the
          patient will be asked to give their consent for the processing
          described above.
        </p>
        <h3 class="text-2xl">3. Cookies</h3>
        <p>
          When using Sophus Nutrition, cookies are saved on your computer.
          Cookies are small pieces of information (in the form of text) that a
          server sends to your browser (such as Internet Explorer or Firefox)
          with the intention that the browser sends this information back to the
          server the next time a user makes use of the Service. Cookies cannot
          damage your computer or the files saved on it.
        </p>
        <p>
          When you use the Service, first party cookies are saved on your
          computer. First party cookies are made by or for Sophus Nutrition and
          are stored on your computer by Sophus Nutrition and only Sophus
          Nutrition has access to these cookies. Such cookies are used by Sophus
          Nutrition, for example, to remember your login information.
        </p>
        <p>
          In order to collect data on the usage of Sophus Nutrition’s website
          (the marketing website, not the platform used for access to the
          Service), Sophus Nutrition uses Google Analytics. Google Analytics
          stores a permanent cookie on your computer which is subsequently used
          to register your use of the website. This data is then analyzed by
          Google and the results are given to Sophus Nutrition. This enables
          Sophus Nutrition to get more insight in the way in which the website
          is used and, based on this information, to make adjustments to the
          website or the provided services.
        </p>
        <p>
          You can configure your browser so that you do not receive any cookies
          the next time you use the Service. However, it is then possible that
          you will no longer be able to make full use of Sophus Nutrition.
        </p>
        <h3 class="text-2xl">
          4. For what purposes will Sophus Nutrition use personal data about
          you?
        </h3>
        <p>
          Sophus Nutrition may use your personal data for the following
          purposes:
        </p>
        <p>
          To allow the healthcare provider to use the Service, including the
          management of programs for patients, the management of the patient’s
          compliance with the program and the exchange of program templates with
          other users of Sophus Nutrition.
        </p>
        <p>
          To allow the patient to use the Service, including the access to home
          exercise programs provided by the healthcare provider and monitoring
          the compliance and providing feedback to the healthcare provider.
        </p>
        <p>To process payments by healthcare providers.</p>
        <p>
          To communicate with you about the Service and/or other services of
          Sophus Nutrition;
        </p>
        <p>To configure Sophus Nutrition to your wishes and needs.</p>
        <p>
          For protection purposes and to generate anonymous statistical data.
        </p>
        <p>
          Sophus Nutrition will only provide medical information to third
          parties if the healthcare provider has given its consent for the
          specific third party involved (for instance, an insurance company) and
          if such information is aggregated to make sure that the patient cannot
          be identified.
        </p>
        <p>
          Sophus Nutrition will only provide your personal data to third parties
          other than as set out in this Privacy Policy in the following cases:
        </p>
        <p>
          if it is obliged to do so based on the Agreement with the healthcare
          provider;
        </p>
        <p>
          if it is obliged to do so on account of national or international
          laws, case law and/or regulations;
        </p>
        <p>
          if Sophus Nutrition considers it necessary to do so in defense of its
          own rights; or
        </p>
        <p>if you have given permission to do so.</p>
        <p>
          Sophus Nutrition may post customer testimonials/comments/reviews on
          the Website, which may contain personal data of healthcare providers.
          Sophus Nutrition shall obtain the healthcare provider’s consent via
          email prior to posting the testimonial.
        </p>
        <p><em>Customer.io</em></p>
        <p>
          Sophus Nutrition uses third-party analytics services to help
          understand the usage of the Service by healthcare providers. No
          patient information is shared through these services.
        </p>
        <p>
          In particular, we provide a limited amount of the personal data of the
          healthcare provider (such as your email address and sign-up date to
          Peaberry Software, Inc. (“Customer.io”) and utilize Customer.io to
          collect data for analytics purposes when you visit the Website or use
          the Service. Customer.io analyzes your use of our Website and/or
          Service and tracks our relationship so that Sophus Nutrition can
          improve its service to you. We may also use Customer.io as a medium
          for communications, either through email, or through messages within
          the Service.
        </p>
        <p>
          Customer.io is a company that is based in the United States. Sophus
          Nutrition and Customer.io have an EC Data Protection Agreement to
          protect the privacy of Sophus Nutrition’s users.
        </p>
        <p>
          Sophus Nutrition also uses sub-processors. When personal data is
          processed by sub-processors, Sophus Nutrition has data processing
          agreements in place with these sub-processors.
        </p>
        <h3 class="text-2xl">
          5. How does Sophus Nutrition protect your personal data?
        </h3>
        <p>
          Sophus Nutrition takes appropriate technical and organizational
          measures to protect your (personal) data against loss or any form of
          unlawful use. Because of the medical nature of some of the personal
          data, Sophus Nutrition has incorporated a very high level of security.
        </p>
        <p>
          To protect the confidentiality and integrity of your personal data,
          we:
        </p>
        <p>
          Have internal policies that keep your data private and confidential.
        </p>
        <p>
          Encrypt all communications between Sophus Nutrition and our users
          (http: via SSL, email via TLS).
        </p>
        <p>
          Encrypt all patient health information in our database (“at-rest”).
        </p>
        <p>
          Limit information access inside our company to the absolute minimum
          necessary.
        </p>
        <p>Use an electronically and physically secured data center.</p>
        <p>
          Use a firewall which blocks access by attackers and unauthorized
          users.
        </p>
        <p>
          Automatically logoff healthcare providers after a certain period of
          inactivity.
        </p>
        <p>
          Require all of our users to choose strong passwords, and choose a new
          password every 90 days.
        </p>
        <p>
          Use a world-class CDN (content distribution network) which filters out
          possible attackers
        </p>
        <p>Use state-of-the art development and testing systems.</p>
        <p>Use best-in-class server management technologies.</p>
        <h3 class="text-2xl">
          6. Viewing, changing and deleting your personal data
        </h3>
        <p>
          If you wish to know what personal data Sophus Nutrition has collected
          about you or if you wish to change data that you cannot change
          yourself in your Account, then you can send your request to
          hello@sophus.com.au. Sophus Nutrition will provide you with the
          personal data within 4 weeks.
        </p>
        <p>
          Sophus Nutrition will retain your personal data for as long as your
          Account is active or as needed to provide the Service to you, to
          resolve disputes, enforce agreements or comply with any legal
          obligations. If you wish to delete your Account or request that Sophus
          Nutrition no longer uses your personal data, you can contact us at
          hello@sophus.com.au.
        </p>
        <h3 class="text-2xl">7. Can this policy be changed?</h3>
        <p>
          It is possible for this policy to be amended in the future. Any
          changes to the policy will be mentioned on the Website, so it is
          recommended to regularly have a look at the Website.
        </p>
        <h3 class="text-2xl">8. Questions?</h3>
        <p>
          If you have any questions, please do not hesitate to contact us via
          hello@sophus.com.au
        </p>
        <p>
          <em>Last modified: 26 November 2018</em>
        </p>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-28">
    <div class="app-container">
      <div class="max-w-sm mx-auto text-center">
        <h2 class="text-3xl">Understand how Sophus can work for your team</h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
