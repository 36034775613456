import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { CtaComponent } from "./components/cta/cta.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LandingLinksPopoverComponent } from "./components/landing-links-popover/landing-links-popover.component";
import { MobileMenuComponent } from "./components/mobile-menu/mobile-menu/mobile-menu.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NotifyLibModule } from "@sophus/notify";
import { ScrollToDirective } from "./directives/scroll-to/scroll-to.directive";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    CtaComponent,
    LandingLinksPopoverComponent,
    MobileMenuComponent,
    ScrollToDirective,
    RedirectComponent,
    NotFoundComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    NotifyLibModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    CtaComponent,
    IonicModule,
    ScrollToDirective,
  ],
  providers: [],
})
export class SharedModule {}
